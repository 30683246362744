
import React, { Link } from 'react';
import { FunctionField, ReferenceArrayField, SingleFieldList, ChipField, SimpleShowLayout, TextField, Show, useTranslate } from 'react-admin';
import { EditButton } from 'react-admin';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };
import { FormattedDateField, ValidateField } from '../../control/field/index'
import { DeleteButton } from '../../control/button/index'
import CardActions from '@material-ui/core/CardActions';
import ActivateIcon from '@material-ui/icons/Check';
import NotActivateIcon from '@material-ui/icons/Clear';
import { TRUE, FALSE } from '../../util/constant';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* {typeof data != 'undefined' &&  typeof data.published != 'undefined' && data.published === FALSE &&
            <DeleteButton content="Etes-vous sur de vouloir supprimer ce code remise?" redirect='list' basePath={basePath} record={data} resource={resource}/>
        } */}

    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.promo_code.show')}: {record ? `${record.name}` : ''}</span>;

};

const UnitTypeTextField = ({ source, record = {}, label }) => {
    const unitType = record.unit_type;
    return <span class="MuiTypography-root MuiTypography-body2">{unitType === 0 ? 'FCFA' : '%'}</span>;
}

UnitTypeTextField.defaultProps = {
    addLabel: true,
};

const ToAllField = ({ source, record = {}, label }) => {
    // const classes = useStyles();
    const toAllVals = record[source];
    if (typeof (toAllVals) === 'undefined' || toAllVals.length === 0)
        return <div><NotActivateIcon color='disabled' /></div>;
    return <div><ActivateIcon color='primary' /></div>;

};
// <div>
//     <span class>{record[source]}</span>
//     <img src={record[source] == TRUE ? ActivateIcon : NotActivateIcon} 
//         width='20' height='20'
//         alt={''}/>
// </div>;

ToAllField.defaultProps = {
    addLabel: true,
};

const ShowPromoCode = ({ staticContext, permissions, ...props }) => {
    if (permissions !== 'Admin') return <span>Aucune permission</span>;
    return <Show title={<Title />} {...props} actions={<ShowActions />}>

        <SimpleShowLayout>

            <TextField source="code" label="Code" />
            <TextField source="name" label="Nom" />
            <TextField source="description" label="Description" />
            <TextField source="value" label="Valeur" />
            <UnitTypeTextField source="unit_type" label="Unité" />
            {/* <TextField source="amount_min" label = "Montant min. de commande pour appliquer (€)"/> */}
            <TextField source="max_count" label="Nombre maximum d'utlisation par client" />

            <FormattedDateField source="begin_time" label="Date de début de validité" locales="fr-FR" format="format_date_time" />
            <FormattedDateField source="end_time" label="Date de fin de validité" locales="fr-FR" format="format_date_time" />

            <ToAllField source="to_all_clients" label='Envoyer à tous les clients' />
            <ReferenceArrayField label="Clients" reference="client" source="client_ids" basePath="">
                <SingleFieldList>
                    <FunctionField label="" render={record => `${record.first_name} ${record.last_name} (${record.email}) `} />
                </SingleFieldList>
            </ReferenceArrayField>

            {/* <FormattedDateField source="create_time" label="Date de création"  locales="fr-FR" format="format_date_time" /> */}
            <ValidateField source="published" label="Publié" />

        </SimpleShowLayout>
    </Show>
};


export default ShowPromoCode;
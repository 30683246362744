import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Confirm, crudUpdateMany, crudUpdate, useUpdate } from 'react-admin';
import ValidateIcon from '@material-ui/icons/Check';
// import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import { Status } from '../../util/constant'

class CustomerUnlockButton extends Component {
    state = {
        isOpen: false,
        record: { locked: 1 },
    }

    // constructor(props){
    //     super(props);
    //     const {record} = this.props
    //     this.setState({validated: record.validated});
    //     console.log('Validate button state ' + JSON.stringify(this.state));
    // }

    // componentDidUpdate(prevProps, prevState) {
    //     if(prevProps.record!==this.props.record){
    //       this.setState({record: this.props.record});
    //     }
    //   }

    //   static getDerivedStateFromProps(nextProps, prevState){
    //     if(nextProps.record !== prevState.record){
    //       return {record : nextProps.record};
    //     }
    //     else return null;
    //   }

    handleClick = () => {
        this.setState({ isOpen: true });
    }

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    handleConfirm = () => {
        console.log("Handle button props " + JSON.stringify(this.props));
        const { basePath, crudUpdate, resource, record } = this.props;
        // crudUpdate(resource, record.id, {validated: 1}, basePath + '/' + record.id + '/show');
        crudUpdate('customer_unlock', record.id, { locked: Status.UNLOCKED }, basePath, 'show', true);
        this.setState({ isOpen: false });
    };

    static defaultProps = {
        confirmTitle: 'Déverrouillage',
        confirmMessage: 'Are you sure to mark these objects as handled?',
        locked: 0,
    }


    render() {
        let message = "Êtes-vous sûr de vouloir déverrouiller ce compte?";
        return (
            <Fragment>
                {typeof (this.state.record) !== 'undefined' && this.state.record.locked &&
                    <Button label='Déverrouiller' onClick={this.handleClick}>
                        <UnlockIcon color='primary' />
                    </Button>}
                <Confirm
                    isOpen={this.state.isOpen}
                    title={this.props.confirmTitle}
                    content={message}
                    onConfirm={this.handleConfirm}
                    onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }
    // handleClick = () => {
    //     const { push, record, showNotification } = this.props;
    //     const updatedRecord = { ...record, is_approved: true };
    //     fetch(`/comments/${record.id}`, { method: 'PUT', body: updatedRecord })
    //         .then(() => {
    //             showNotification('Comment approved');
    //             push('/comments');
    //         })
    //         .catch((e) => {
    //             showNotification('Error: comment not approved', 'warning')
    //         });
    // }

    // render() {
    //     return <Button label="Approve" onClick={this.handleClick} />;
    // }

}

// BatchHandleButton.propTypes = {
//     confirmTitle: PropTypes.string,
//     confirmMessage: PropTypes.string,

// };

export default connect(undefined, { crudUpdate })(CustomerUnlockButton);
// export default ValidateButton;

// HandleButton.propTypes = {
//     push: PropTypes.func,
//     record: PropTypes.object,
//     showNotification: PropTypes.func,
// };

// export default connect(null, {
//     showNotification,
//     push,
// })(HandleButton);